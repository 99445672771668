/* Define the animation */
@keyframes slideOut {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
  
/* Apply the animation when the image should disappear */
.img-disappear {
    animation: slideOut 1s forwards;
}

.img-appear {
    animation: slideIn 0.5s forwards;
  }